import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Bowl_Of_Hygieia_by_David.svg/1200px-Bowl_Of_Hygieia_by_David.svg.png";
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Nordic Giant Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>О нас</h2>

              <p>Мы занимаеся производством фармацевтических препаратов более 10 лет! Мы являемся вендором более 100 компании в Републике Казахстан!</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Контактные данные</h2>
                  <p className="address">
                    <span>RMK Plus</span>
                    <br />
                    <span>
                       ул. Навои, 58
                      <br />
                      Казахстан, Алматы, 050000
                    </span>
                    <br />
                    <span>+7 705 123 7788</span>
                    <br />
                    <span>info@rmk-plus.kz</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
